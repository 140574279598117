import './Why.scss';
import { Row } from 'react-bootstrap';
import IntroImage from '../../../Images/LandingSpot/xpektor_moln_ny.png';
import Timer from '../../../Images/Why/timer.png';
import Handshake from '../../../Images/Why/handshake.png';
import SKVIcon from '../../../Images/Why/SKV_icon.png';
import AccountingIcon from '../../../Images/Why/Accounting_icon.png';
import BankIcon from '../../../Images/Why/Bank_icon.png';
import LandingNavigation from '../LandingNavigation/LandingNavigation';
import Contact from '../../../Components/Contact/Contact';

export default function Why() {
  return (
    <div className="why-wrapper">
      <Row className="home-page-intro">
        <LandingNavigation />
        <div className="home-intro-content">
          <div className="home-intro-column">
            <h1 className="home-content-title">Varför Xpektor</h1>
            <p>
              För kredit och långivare är det viktigt att få en så komplett och
              tydlig bild som möjligt över ett företags ekonomiska situation
              innan eller i samband med att en affärsmässig relation inleds. Här
              kommer Xpektor in i bilden.
              <br />
              <br />
              Att förlita sig på gammal (historisk) data vid kreditbeslut ger
              inte bara en begränsad bild av företaget utan kan också innebära
              att företag inte beviljas lån om man exempelvis kommer från ett
              tufft ekonomiskt år. Med hjälp av Xpektor får fler möjlighet att
              få finansiering eftersom Xpektor hjälper till att analysera
              företags nulägessituation
            </p>
          </div>
          <div className="home-intro-column intro-image-column">
            <img
              src={IntroImage}
              alt="Intro alt"
              className="home-intro-image"
            />
          </div>
        </div>
      </Row>
      <div className="why-row">
        <div className="why-row-text-column">
          <h2>Realtidsinformation viktigare än någonsin</h2>
          <p>
            I en tid av skenande bedrägerier och ekonomisk brottslig gör lån och
            kreditgivare allt för att hantera riskmoment. Därför är det i
            princip ingen som beviljar ett lån eller kredit på enbart historisk
            information. Så som att enkom förlita sig på föregåendeårs balans
            och resultatrapport. Branschpraxis är att ha så uppdaterade siffror
            och information som möjligt varför den som söker lån ofta behöver
            inkomma med kompletteringar. Så som transaktionshistorisk från
            banken, resultat/balans och mer därtill.
          </p>
        </div>
        <img src={Timer} alt="Timer" className="why-row-image" />
      </div>
      <div className="why-row">
        <img src={Handshake} alt="Handshake" className="why-row-image" />
        <div className="why-row-text-column">
          <h2>Med Xpektors hjälp sparar alla tid</h2>
          <p>
            Den som ansöker om ett företagslån eller kredit slipper fundera på
            om rätt information delas, efter det att tid lagts på att hitta den
            det vill säga. Antingen på egenhand eller med hjälp av kanske sin
            redovisningsekonom. Långivaren behöver inte lägga tid och resurser
            på att begära in och granska denna kritiska information utan kan
            direkt sätta i gång och påbörja handläggningen i och med att en
            komplett bild över företaget visas i Xpektor. För långivaren innebär
            Xpektor mer träffsäkra kreditbedömningar och effektivare
            handläggning. Lånsökaren kan känna sig trygg med att rätt
            information delas för att fatta beslut.
          </p>
        </div>
      </div>
      <div className="why-connections-row">
        <div className="why-connection-column">
          <img
            src={AccountingIcon}
            alt="Accounting"
            className="why-column-image"
          />
          <h2>Varför bokföring</h2>
          <p>
            Genom att koppla bokföring till Xpektor skapas vad som liknar en
            dagsfärsk årsredovisning och handläggaren får en tydlig bild över
            företagets ekonomiska situation. Xpektor läser inte bokföring på
            transaktionsnivå vilket innebär att Xpektor inte tittar på varje
            enskild transaktion. Något som kan vara känsligt. Istället summerar
            Xpektor helheten och kan utläsa all väsentlig information utifrån
            det.
          </p>
        </div>
        <div className="why-connection-column">
          <img src={BankIcon} alt="Accounting" className="why-column-image" />
          <h2>Varför företagskonto</h2>
          <p>
            För långivaren är det viktigt att få en förståelse för vad som
            händer på låntagarens företagskonto. Betalas pengar till
            högriskländer? Sker betalningar till kronofogden? Används pengarna
            på ett ställe samtidigt som företaget är registrerat någon
            annanstans? Detta är bara exempel på viktiga frågor som en
            långivaren vill och behöver förstå innan en affärsmässig förbindelse
            kan inledas.
          </p>
        </div>
        <div className="why-connection-column">
          <img src={SKVIcon} alt="Accounting" className="why-column-image" />
          <h2>Varför Skatteverket</h2>
          <p>
            Ett allt vanligare sätt att utföra ekonomisk brottslighet är via
            skattekontot varför en långivare gärna vill få en inblick. Det är
            också viktigt att säkerställa att den som söker lån eller kredit är
            registrerad på ett korrekt sätt. Exempelvis för F-skatt och i
            arbetsgivarregistret.
          </p>
        </div>
      </div>
      <footer>
        <Contact />
      </footer>
    </div>
  );
}
