import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
import Login from './Pages/Home/Login/Login';
import AuthorizeFortnox from './Components/Authorize/AuthorizeFortnox';
import LandingSpot from './Pages/Home/LandingSpot';
import ForWhom from './Pages/Home/ForWhom/ForWhom';
import Product from './Pages/Home/Product/Product';
import About from './Pages/Home/About/About';
import Offers from './Pages/Home/Offers/Offers';
import PrivacyPolicy from './Pages/Home/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Pages/Home/TermsAndConditions/TermsAndConditions';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthorizePresigned from './Components/Authorize/AuthorizePresigned';
import AuthorizeEnableBanking from './Components/Authorize/AuthorizeEnableBanking';
import AuthorizeVisma from './Components/Authorize/AuthorizeVisma';
import ExternalAuthorization from './Pages/ExternalAuthorization/ExternalAuthorization';
import Tutorials from './Pages/Home/Tutorials/Tutorials';
import Why from './Pages/Home/Why/Why';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <LandingSpot />
        </Route>
        <Route exact path="/product">
          <Product />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/target">
          <ForWhom />
        </Route>
        <Route exact path="/offers">
          <Offers />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/authorize">
          <AuthorizeFortnox />
        </Route>
        <Route exact path="/authorizeVisma">
          <AuthorizeVisma />
        </Route>
        <Route exact path="/presigned">
          <AuthorizePresigned />
        </Route>
        <Route exact path="/ebUpdate">
          <AuthorizeEnableBanking />
        </Route>
        <Route exact path="/authorizeBanking">
          <AuthorizeEnableBanking />
        </Route>
        <Route exact path="/authorization">
          <ExternalAuthorization />
        </Route>
        <Route exact path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/termsandconditions">
          <TermsAndConditions />
        </Route>
        <Route exact path="/tutorials">
          <Tutorials />
        </Route>
        <Route exact path="/why">
          <Why />
        </Route>
        <Route>
          <App appTitle="Xpektor" />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();
